<template>
    <div>
        <!-- Header -->
        <div class="bg-slate-900 py-4">
            <div class="relative w-full max-w-5xl mx-auto px-8">
                <div class="grid grid-cols-6 lg:grid-cols-2 gap-8">
                    <div class="col-span-2 lg:col-span-1 flex justify-start items-center">
                        <img class="max-h-32" src="@/assets/logo-no-background.png" alt="">
                    </div>
                    <div class="col-span-4 lg:col-span-1 flex justify-end items-center">
                        <div class="text-right">
                            <h1 class="font-google-sans-medium text-lg lg:text-2xl text-white font-medium mb-2">Service status</h1>
                            <h2 class="font-google-sans-regular text-xs lg:text-sm text-white font-normal">Last updated: {{ last_checked }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div class="py-8">
            <div class="relative w-full max-w-5xl mx-auto px-8">
                
                <!-- Overall Service Status -->
                <div class="flex items-center justify-center mb-8 py-8">
                    <div :class="['h-8 w-8 rounded-full mr-5 animate-pulse', overallStatusColor]"></div>
                    <span class="font-google-sans-medium text-3xl" v-html="overallStatus"></span>
                </div>


                <!-- Service Status -->
                <div v-for="service in services" :key="service.id" class="px-5 py-6 border-b last:border-none">
                    <div class="grid grid-cols-12 gap-5 items-center">
                        <div class="col-span-8 sm:col-span-6 lg:col-span-4">
                            <a href="" class="">
                                <h1 class="font-google-sans-medium text-lg text-slate-900 font-medium mb-1 leading-5">{{ service.name }}</h1>
                                <h2 class="font-google-sans-regular text-sm text-slate-900 font-normal leading-5">{{ service.hostname }}</h2>
                            </a>
                        </div>
                        <div class="hidden sm:block col-span-2 lg:col-span-1">
                            <h2 class="font-google-sans-medium text-sm text-slate-800">{{ service.uptime }}%</h2>
                        </div>
                        <div class="hidden sm:block col-span-2 lg:col-span-1">
                            <h2 class="font-google-sans-medium text-sm text-slate-800">{{ service.responseTime }}ms</h2>
                        </div>
                        <div class="hidden lg:block lg:col-span-4">
                            <div class="grid grid-flow-col-dense justify-between">
                                <div v-for="history in service.history" :key="history.date" class="col-auto">
                                    <div :class="`h-5 w-1.5 rounded-full ${getUptimeColor(history.uptime)}`" class="relative group">
                                        <div class="absolute bottom-full left-1/2 w-36 bg-gray-800 text-white px-2 py-1 rounded-md opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                            <span v-if="!history.status">
                                                <p class="text-sm">{{ history.date }}</p>
                                                <p class="text-sm">Uptime: {{ history.uptime }}%</p>
                                                <p class="text-sm">Latency: {{ history.responseTime }}ms</p>
                                            </span>
                                            <span v-else>
                                                <p class="text-sm">{{ history.date }}</p>
                                                <p class="text-sm">No data available</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="service.status === 'operational'" class="col-span-4 sm:col-span-2 lg:col-span-2 flex items-center justify-end">
                            <div class="h-2 w-2 bg-green-500 rounded-full mr-2 animate-pulse"></div>
                            <span class="font-google-sans-medium text text-green-500">Up</span>
                        </div>
                        <div v-else-if="service.status === 'degraded'" class="col-span-4 sm:col-span-2 lg:col-span-2 flex items-center justify-end">
                            <div class="h-2 w-2 bg-yellow-500 rounded-full mr-2 animate-pulse"></div>
                            <span class="font-google-sans-medium text text-yellow-500">Degraded</span>
                        </div>
                        <div v-else class="col-span-4 sm:col-span-2 lg:col-span-2 flex items-center justify-end">
                            <div class="h-2 w-2 bg-red-500 rounded-full mr-2 animate-pulse"></div>
                            <span class="font-google-sans-medium text text-red-500">Down</span>
                        </div>
                    </div>
                </div> 
                

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            last_checked: 'Loading',
            services: []
        }
    },
    mounted() {
        this.getServices();
    },
    methods: {
        getServices() {
            axios.get('https://orion.alantiller.app/api.php?get=servicestatus')
            .then(response => {
                this.last_checked = response.data.last_checked;
                this.services = response.data.monitors.map(monitor => {
                    monitor.history = monitor.history.reverse();
                    return monitor;
                });
            })
            .catch(error => {
                console.log(error);
            });
        },
        getUptimeColor(uptime) {
            if (uptime == 100) {
                return 'bg-green-500';
            } else if (uptime >= 90 && uptime < 100) {
                return 'bg-green-400';
            } else if (uptime >= 75 && uptime < 90) {
                return 'bg-green-300';
            } else if (uptime >= 50 && uptime < 75) {
                return 'bg-amber-400';
            } else if (uptime >= 10 && uptime < 50) {
                return 'bg-amber-300';
            } else if (uptime < 10) {
                return 'bg-red-500';
            } else {
                return 'bg-gray-300';
            }                                     
        }
    },
    computed: {
        overallStatus() {
            const downServices = this.services.filter(service => service.status === 'down').length;
            const degradedServices = this.services.filter(service => service.status === 'degraded').length;
            const totalServices = this.services.length;

            if (totalServices === 0) return 'No services are currently being monitored';

            if (downServices === totalServices) {
                return 'All services are currently <span class="text-red-500">down</span>';
            } else if (downServices > 0) {
                return 'Some services are currently <span class="text-amber-500">down</span>';
            } else if (degradedServices > 0) {
                return 'Some services are currently <span class="text-amber-500">degraded</span>';
            } else {
                return 'All services are currently <span class="text-green-500">operational</span>';
            }
        },
        overallStatusColor() {
            const downServices = this.services.filter(service => service.status === 'down').length;
            const degradedServices = this.services.filter(service => service.status === 'degraded').length;
            const totalServices = this.services.length;

            if (totalServices === 0) return 'bg-slate-900';

            if (downServices === totalServices) {
                return 'bg-red-500';
            } else if (downServices > 0) {
                return 'bg-amber-500';
            } else if (degradedServices > 0) {
                return 'bg-amber-500';
            } else {
                return 'bg-green-500';
            }
        }
    }

}
</script>