<template>
    <div class="bg-slate-800">
        <div class="py-5 text-white">
            <div class="px-8 relative w-full max-w-5xl mx-auto">
                <p class="text"><span class="font-google-sans-regular font-thin">Built by <a href="https://www.alantiller.com" class="underline" target="blank">Alan Tiller</a></span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageFooter',
    components: {
    },
    methods: {
        
    }
}
</script>