import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import NotFoundView from '../views/NotFoundView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: NotFoundView,
        meta: { 
            requiresAuth: false
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// Add a navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        var test = true;
        if (test === false) {
            next('/clients/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router