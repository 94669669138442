<template>
    <div>
        <!-- Coming soon page -->
        <div class="py-28">
            <div class="relative w-full max-w-3xl mx-auto px-8 text-center">
                <p class="font-google-sans-regular text-lg xl:text-2xl font-thin m-0 text-neutral-900">Oops, you caught me off guard! I was hoping to keep this page a secret, but you’ve stumbled upon it. Why don’t you go back to my <router-link to="/" class="inline-block underline text-blue-700">homepage</router-link> where the parties at? 🎉</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundView',
    components: {
    }
}
</script>